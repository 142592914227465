import '@formkit/themes/genesis';
import '@formkit/pro/genesis';
import { generateClasses } from '@formkit/themes';
import theme from './formkit.theme';
import {
  close,
  down,
  fileDoc,
  check,
  circle,
  spinner,
  star,
  trash,
  add,
  arrowUp,
  arrowDown,
  applicationIcons,
  genesisIcons,
} from '@formkit/icons';
import { DefaultConfigOptions } from '@formkit/vue';
import { createProPlugin, inputs } from '@formkit/pro';
import { FormKitNode } from '@formkit/core';
import {fileSize} from "~/custom-validation-rules/file-size";
import {maxValidDays, minValidDays} from "@tsamm-v2/sa-help/custom-validation-rules/min-valid-days";

const pro = createProPlugin('fk-644908e468', inputs);
const legends = ['checkbox_multi', 'radio_multi', 'repeater', 'transferlist'];

const isCheckboxAndRadioMultiple = (node: FormKitNode) =>
  (node.props.type === 'checkbox' || node.props.type === 'radio') && node.props.options;

function addOptionalLabelPlugin(node: FormKitNode) {
  if (['button', 'submit', 'hidden', 'group', 'list', 'meta'].includes(node.props.type)) return;
  const { t } = useI18n();
  node.on('created', () => {
    const legendOrLabel = legends.includes(`${node.props.type}${node.props.options ? '_multi' : ''}`) ? 'legend' : 'label';

    if (node.props.definition.schemaMemoKey) {
      node.props.definition.schemaMemoKey = `${node.name}-${Date.now()}-${Math.random()}`;
    }

    const schemaFn = node.props.definition.schema
    node.props.definition.schema = (sectionsSchema = {}) => {
      sectionsSchema[legendOrLabel] = {
        children: [{
          $el: 'span',
          attrs: {
            innerHTML: `${node.props.label}`,
          }
        }, {
          $el: 'span',
          if: '$state.required === false',
          attrs: {
            class: 'text-[20px] font-light text-gray-400 pl-2',
          },
          children: [node.name !== 'allowContact' ? `— Optional` : '']
        }]
      }

      return schemaFn(sectionsSchema)
    }
  })

  node.on('created', async () => {
    if(node.props.type === 'number') {
      setTimeout(() => {
        if (document.getElementById(`${node.props.id}`) !== null) {
          document.getElementById(`${node.props.id}`)?.addEventListener('keydown', function (e) {
            if (e.key === 'e' || e.key === 'E') {
              e.preventDefault();
            }
          });
        }
      }, 1000);
    }
  });

  node.hook.submit((payload: any, next: any) => {
    node?.children?.forEach((child: any) => {
      if (child.context?.type === 'mask' && child.context?.mask === '{#,##|repeat}#.##') {
        if (child.context?.value.length === 1) {
          payload[child.name] = `${parseInt(child.context?.value) * 100}`;
        }
      }
    });
    return next(payload);
  });
}
const convertToPrice = (price: string) => {
  return (parseInt(price)).toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD'
  });
}
function formatDateRequirement(args: any) {
  if (!args || !Array.isArray(args) || args.length === 0 || typeof args[0] !== 'string') {
    return 'Invalid date.';
  }

  const dates = args[0].split('-');
  if (dates.length !== 3 || dates.some(date => !date)) {
    return 'Invalid date.';
  }

  const formattedDate = `${dates[1]}/${dates[2]}/${dates[0]}`;
  return `Must be after ${formattedDate}.`;
}
function formatDateRangeRequirement(args: any) {
  if (!args || !Array.isArray(args) || args.length < 2) {
    return '';
  }

  if (typeof args[0] !== 'string' || typeof args[1] !== 'string') {
    return '';
  }

  const dates = args[0].split('-');
  if (dates.length !== 3 || dates.some(date => !date)) {
    return '';
  }

  const dates2 = args[1].split('-');
  if (dates2.length !== 3 || dates2.some(date => !date)) {
    return '';
  }

  const formattedDate = `${dates[1]}/${dates[2]}/${dates[0]}`;
  const formattedDate2 = `${dates2[1]}/${dates2[2]}/${dates2[0]}`;
  return `Must be between ${formattedDate} and ${formattedDate2}.`;
}
const config: DefaultConfigOptions = {
  messages: {
    en: {
      validation: {
        required() {
          return `This field is required.`;
        },
        min({ args, node }: { args: any, name: string, node: FormKitNode }) {
          if (node.props.type === 'currency') {
            return `Must be greater than ${convertToPrice(args[0])}.`;
          }
          return `Must be greater than ${args[0]}.`;
        },
        max({ args, node }: { args: any, name: string, node: FormKitNode }) {
          if (node.props.type === 'currency') {
            return `Cannot be greater than ${convertToPrice(args[0])}.`;
          }
          return `Cannot be greater than ${args[0]}.`;
        },
        between({ args, node }: { args: any, name: string, node: FormKitNode }) {
          if (node.props.type === 'currency') {
            return `Must be between ${convertToPrice(args[0])} and ${convertToPrice(args[1])}.`;
          }
          return `Must be between ${args[0]} and ${args[1]}.`;
        },
        date_between({ args }: { args: any, name: string}) {
          return formatDateRangeRequirement(args);
        },
        date_before({ args }: { args: any }) {
          return formatDateRequirement(args);
        },
        date_after({ args }: { args: any }) {
          return formatDateRequirement(args);
        },
        length({ name, args, node }: { args: any, name: string, node: FormKitNode }) {
          if (node.context?.type === 'mask' && node.context?.mask === '{#,##|repeat}#.##') {
            return `Please enter a minimum of 2 digits.  For example, 0 should be entered as 00.`;
          } else {
            if (args.length > 1) {
              return `Limited to ${parseInt(args[1]).toLocaleString('en-US')} characters.`;
            } else {
              return `${name} must be greater than or equal to ${args[0]} characters.`;
            }
          }
        },
        fileSize() {
          return `The file size must be smaller than 50MB.`;
        }
      },
    },
  },
  config: {
    classes: generateClasses(theme),
  },
  rules: {
    fileSize,
  },
  plugins: [pro, addOptionalLabelPlugin],
  icons: {
    ...applicationIcons,
    ...genesisIcons,
    close,
    down,
    fileDoc,
    check,
    circle,
    spinner,
    star,
    trash,
    add,
    arrowUp,
    arrowDown,
    checkboxDecorator: check,
  },
};
export default config;
