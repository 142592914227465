import { default as index1pkDHedukRMeta } from "/home/vsts/work/1/s/packages/admin-dashboard/pages/access-code/index.vue?macro=true";
import { default as static_45must_45be_45deletedgmIdCskqvCMeta } from "/home/vsts/work/1/s/packages/admin-dashboard/pages/access-code/static-must-be-deleted.vue?macro=true";
import { default as additional_45assistance_45types1lYq4bS1ZWMeta } from "/home/vsts/work/1/s/packages/admin-dashboard/pages/admin/additional-assistance-types.vue?macro=true";
import { default as reportVMhpw9K0YfMeta } from "/home/vsts/work/1/s/packages/admin-dashboard/pages/admin/advanced-filters/report.vue?macro=true";
import { default as edit2iOFpd68P4Meta } from "/home/vsts/work/1/s/packages/admin-dashboard/pages/admin/custom-questions/[id]/edit.vue?macro=true";
import { default as createtTdxrdFJYAMeta } from "/home/vsts/work/1/s/packages/admin-dashboard/pages/admin/custom-questions/create.vue?macro=true";
import { default as index6jvIJZ91FAMeta } from "/home/vsts/work/1/s/packages/admin-dashboard/pages/admin/custom-questions/index.vue?macro=true";
import { default as availability_45appointmentsFvpIb6LkGLMeta } from "/home/vsts/work/1/s/packages/admin-dashboard/pages/admin/disaster-assistance/availability-appointments.vue?macro=true";
import { default as calendar_45appointmentshQhi0IyiKrMeta } from "/home/vsts/work/1/s/packages/admin-dashboard/pages/admin/disaster-assistance/calendar-appointments.vue?macro=true";
import { default as disaster_45appointmentsPXgMULjv7VMeta } from "/home/vsts/work/1/s/packages/admin-dashboard/pages/admin/disaster-assistance/disaster-appointments.vue?macro=true";
import { default as _91id_93L6dKlacGJ3Meta } from "/home/vsts/work/1/s/packages/admin-dashboard/pages/admin/disaster-assistance/disaster-pantry/[id].vue?macro=true";
import { default as index8Cmn0H8IeQMeta } from "/home/vsts/work/1/s/packages/admin-dashboard/pages/admin/disaster-assistance/index.vue?macro=true";
import { default as indexHUd7fSLejtMeta } from "/home/vsts/work/1/s/packages/admin-dashboard/pages/admin/documents/funding-source/index.vue?macro=true";
import { default as manager2RQFyHJB3Meta } from "/home/vsts/work/1/s/packages/admin-dashboard/pages/admin/documents/funding-source/manage.vue?macro=true";
import { default as index49AfbMcDrcMeta } from "/home/vsts/work/1/s/packages/admin-dashboard/pages/admin/documents/notification/index.vue?macro=true";
import { default as manage4PREAhvIIeMeta } from "/home/vsts/work/1/s/packages/admin-dashboard/pages/admin/documents/notification/manage.vue?macro=true";
import { default as requiredMNEcpO6yA5Meta } from "/home/vsts/work/1/s/packages/admin-dashboard/pages/admin/documents/required.vue?macro=true";
import { default as financial_45assistancemuH86AADD6Meta } from "/home/vsts/work/1/s/packages/admin-dashboard/pages/admin/financial-assistance.vue?macro=true";
import { default as availability_45appointmentsTao6gGCpYDMeta } from "/home/vsts/work/1/s/packages/admin-dashboard/pages/admin/food-assistance/availability-appointments.vue?macro=true";
import { default as calendar_45appointmentsSeCANKoBbEMeta } from "/home/vsts/work/1/s/packages/admin-dashboard/pages/admin/food-assistance/calendar-appointments.vue?macro=true";
import { default as food_45appointmentsrlPlPF3GalMeta } from "/home/vsts/work/1/s/packages/admin-dashboard/pages/admin/food-assistance/food-appointments.vue?macro=true";
import { default as _91id_93niyZyVb9X6Meta } from "/home/vsts/work/1/s/packages/admin-dashboard/pages/admin/food-assistance/food-pantry/[id].vue?macro=true";
import { default as indexo9G6fkHJBjMeta } from "/home/vsts/work/1/s/packages/admin-dashboard/pages/admin/food-assistance/index.vue?macro=true";
import { default as editUvxmKN1Cb6Meta } from "/home/vsts/work/1/s/packages/admin-dashboard/pages/applicants/[id]/edit.vue?macro=true";
import { default as showykQnMuTFdmMeta } from "/home/vsts/work/1/s/packages/admin-dashboard/pages/applicants/[id]/show.vue?macro=true";
import { default as indexhLYVIjeGGaMeta } from "/home/vsts/work/1/s/packages/admin-dashboard/pages/applicants/index.vue?macro=true";
import { default as edittazVw9kcqDMeta } from "/home/vsts/work/1/s/packages/admin-dashboard/pages/applications/[id]/[stepId]/edit.vue?macro=true";
import { default as household_45membersi9XVY42R4rMeta } from "/home/vsts/work/1/s/packages/admin-dashboard/pages/applications/[id]/[stepId]/household-members.vue?macro=true";
import { default as printgejMXA8qiuMeta } from "/home/vsts/work/1/s/packages/admin-dashboard/pages/applications/[id]/print.vue?macro=true";
import { default as showhOVbs8UlAlMeta } from "/home/vsts/work/1/s/packages/admin-dashboard/pages/applications/[id]/show.vue?macro=true";
import { default as indexfF5QMaAlKsMeta } from "/home/vsts/work/1/s/packages/admin-dashboard/pages/applications/index.vue?macro=true";
import { default as _91id_93R03amZKb1ZMeta } from "/home/vsts/work/1/s/packages/admin-dashboard/pages/appointments/[id].vue?macro=true";
import { default as availabilityKcSz11BHfgMeta } from "/home/vsts/work/1/s/packages/admin-dashboard/pages/appointments/availability.vue?macro=true";
import { default as calendarwsVdJhovYwMeta } from "/home/vsts/work/1/s/packages/admin-dashboard/pages/appointments/calendar.vue?macro=true";
import { default as indexfhfnioUvqRMeta } from "/home/vsts/work/1/s/packages/admin-dashboard/pages/appointments/index.vue?macro=true";
import { default as managecfXJpuHrQCMeta } from "/home/vsts/work/1/s/packages/admin-dashboard/pages/appointments/manage.vue?macro=true";
import { default as previewt5qgJSVh58Meta } from "/home/vsts/work/1/s/packages/admin-dashboard/pages/documents/preview.vue?macro=true";
import { default as territoryFSfgsHfxgNMeta } from "/home/vsts/work/1/s/packages/admin-dashboard/pages/dynamic-form/territory.vue?macro=true";
import { default as indexArm6Ch0tllMeta } from "/home/vsts/work/1/s/packages/admin-dashboard/pages/faqs/index.vue?macro=true";
import { default as indexN6VtyMMb2UMeta } from "/home/vsts/work/1/s/packages/admin-dashboard/pages/funding-sources/[id]/index.vue?macro=true";
import { default as print3FUVu6CKniMeta } from "/home/vsts/work/1/s/packages/admin-dashboard/pages/funding-sources/[id]/print.vue?macro=true";
import { default as createyIHUe3HrTcMeta } from "/home/vsts/work/1/s/packages/admin-dashboard/pages/funding-sources/create.vue?macro=true";
import { default as indexwKd1c6YPITMeta } from "/home/vsts/work/1/s/packages/admin-dashboard/pages/funding-sources/index.vue?macro=true";
import { default as indexyYFnJQl3o9Meta } from "/home/vsts/work/1/s/packages/admin-dashboard/pages/funds/[id]/index.vue?macro=true";
import { default as print1oJ3LNouJUMeta } from "/home/vsts/work/1/s/packages/admin-dashboard/pages/funds/[id]/print.vue?macro=true";
import { default as createYoBmBUjcUqMeta } from "/home/vsts/work/1/s/packages/admin-dashboard/pages/funds/create.vue?macro=true";
import { default as indexb3dQ7iAgf2Meta } from "/home/vsts/work/1/s/packages/admin-dashboard/pages/funds/index.vue?macro=true";
import { default as generate_45errorNBH7pbTH5SMeta } from "/home/vsts/work/1/s/packages/admin-dashboard/pages/generate-error.vue?macro=true";
import { default as indexLKTEEP98jpMeta } from "/home/vsts/work/1/s/packages/admin-dashboard/pages/index.vue?macro=true";
import { default as indexCdzWyd9wkTMeta } from "/home/vsts/work/1/s/packages/admin-dashboard/pages/oversight/index.vue?macro=true";
import { default as summaryPEzI3cJCkYMeta } from "/home/vsts/work/1/s/packages/admin-dashboard/pages/pdf/summary.vue?macro=true";
import { default as disaster_45distributionsDRGks3R3i6Meta } from "/home/vsts/work/1/s/packages/admin-dashboard/pages/reports/disaster-distributions.vue?macro=true";
import { default as food_45distributionsDxXOIXzMOcMeta } from "/home/vsts/work/1/s/packages/admin-dashboard/pages/reports/food-distributions.vue?macro=true";
import { default as indexEoLLr2gXkQMeta } from "/home/vsts/work/1/s/packages/admin-dashboard/pages/reports/fund-transactions/index.vue?macro=true";
import { default as indexNYeuzWDHYdMeta } from "/home/vsts/work/1/s/packages/admin-dashboard/pages/schedule/index.vue?macro=true";
import { default as _91id_93G7V10orLwXMeta } from "/home/vsts/work/1/s/packages/admin-dashboard/pages/service-areas/[id].vue?macro=true";
import { default as createEj0eghi3jrMeta } from "/home/vsts/work/1/s/packages/admin-dashboard/pages/service-areas/create.vue?macro=true";
import { default as indexNzvjNDQ4gSMeta } from "/home/vsts/work/1/s/packages/admin-dashboard/pages/service-areas/index.vue?macro=true";
export default [
  {
    name: "access-code",
    path: "/access-code",
    meta: index1pkDHedukRMeta || {},
    component: () => import("/home/vsts/work/1/s/packages/admin-dashboard/pages/access-code/index.vue")
  },
  {
    name: "access-code-static-must-be-deleted",
    path: "/access-code/static-must-be-deleted",
    meta: static_45must_45be_45deletedgmIdCskqvCMeta || {},
    component: () => import("/home/vsts/work/1/s/packages/admin-dashboard/pages/access-code/static-must-be-deleted.vue")
  },
  {
    name: "admin-additional-assistance-types",
    path: "/admin/additional-assistance-types",
    component: () => import("/home/vsts/work/1/s/packages/admin-dashboard/pages/admin/additional-assistance-types.vue")
  },
  {
    name: "admin-advanced-filters-report",
    path: "/admin/advanced-filters/report",
    component: () => import("/home/vsts/work/1/s/packages/admin-dashboard/pages/admin/advanced-filters/report.vue")
  },
  {
    name: "admin-custom-questions-id-edit",
    path: "/admin/custom-questions/:id()/edit",
    component: () => import("/home/vsts/work/1/s/packages/admin-dashboard/pages/admin/custom-questions/[id]/edit.vue")
  },
  {
    name: "admin-custom-questions-create",
    path: "/admin/custom-questions/create",
    component: () => import("/home/vsts/work/1/s/packages/admin-dashboard/pages/admin/custom-questions/create.vue")
  },
  {
    name: "admin-custom-questions",
    path: "/admin/custom-questions",
    component: () => import("/home/vsts/work/1/s/packages/admin-dashboard/pages/admin/custom-questions/index.vue")
  },
  {
    name: "admin-disaster-assistance-availability-appointments",
    path: "/admin/disaster-assistance/availability-appointments",
    component: () => import("/home/vsts/work/1/s/packages/admin-dashboard/pages/admin/disaster-assistance/availability-appointments.vue")
  },
  {
    name: "admin-disaster-assistance-calendar-appointments",
    path: "/admin/disaster-assistance/calendar-appointments",
    component: () => import("/home/vsts/work/1/s/packages/admin-dashboard/pages/admin/disaster-assistance/calendar-appointments.vue")
  },
  {
    name: "admin-disaster-assistance-disaster-appointments",
    path: "/admin/disaster-assistance/disaster-appointments",
    component: () => import("/home/vsts/work/1/s/packages/admin-dashboard/pages/admin/disaster-assistance/disaster-appointments.vue")
  },
  {
    name: "admin-disaster-assistance-disaster-pantry-id",
    path: "/admin/disaster-assistance/disaster-pantry/:id()",
    meta: _91id_93L6dKlacGJ3Meta || {},
    component: () => import("/home/vsts/work/1/s/packages/admin-dashboard/pages/admin/disaster-assistance/disaster-pantry/[id].vue")
  },
  {
    name: "admin-disaster-assistance",
    path: "/admin/disaster-assistance",
    component: () => import("/home/vsts/work/1/s/packages/admin-dashboard/pages/admin/disaster-assistance/index.vue")
  },
  {
    name: "admin-documents-funding-source",
    path: "/admin/documents/funding-source",
    component: () => import("/home/vsts/work/1/s/packages/admin-dashboard/pages/admin/documents/funding-source/index.vue")
  },
  {
    name: "admin-documents-funding-source-manage",
    path: "/admin/documents/funding-source/manage",
    component: () => import("/home/vsts/work/1/s/packages/admin-dashboard/pages/admin/documents/funding-source/manage.vue")
  },
  {
    name: "admin-documents-notification",
    path: "/admin/documents/notification",
    component: () => import("/home/vsts/work/1/s/packages/admin-dashboard/pages/admin/documents/notification/index.vue")
  },
  {
    name: "admin-documents-notification-manage",
    path: "/admin/documents/notification/manage",
    component: () => import("/home/vsts/work/1/s/packages/admin-dashboard/pages/admin/documents/notification/manage.vue")
  },
  {
    name: "admin-documents-required",
    path: "/admin/documents/required",
    component: () => import("/home/vsts/work/1/s/packages/admin-dashboard/pages/admin/documents/required.vue")
  },
  {
    name: "admin-financial-assistance",
    path: "/admin/financial-assistance",
    component: () => import("/home/vsts/work/1/s/packages/admin-dashboard/pages/admin/financial-assistance.vue")
  },
  {
    name: "admin-food-assistance-availability-appointments",
    path: "/admin/food-assistance/availability-appointments",
    component: () => import("/home/vsts/work/1/s/packages/admin-dashboard/pages/admin/food-assistance/availability-appointments.vue")
  },
  {
    name: "admin-food-assistance-calendar-appointments",
    path: "/admin/food-assistance/calendar-appointments",
    component: () => import("/home/vsts/work/1/s/packages/admin-dashboard/pages/admin/food-assistance/calendar-appointments.vue")
  },
  {
    name: "admin-food-assistance-food-appointments",
    path: "/admin/food-assistance/food-appointments",
    component: () => import("/home/vsts/work/1/s/packages/admin-dashboard/pages/admin/food-assistance/food-appointments.vue")
  },
  {
    name: "admin-food-assistance-food-pantry-id",
    path: "/admin/food-assistance/food-pantry/:id()",
    meta: _91id_93niyZyVb9X6Meta || {},
    component: () => import("/home/vsts/work/1/s/packages/admin-dashboard/pages/admin/food-assistance/food-pantry/[id].vue")
  },
  {
    name: "admin-food-assistance",
    path: "/admin/food-assistance",
    component: () => import("/home/vsts/work/1/s/packages/admin-dashboard/pages/admin/food-assistance/index.vue")
  },
  {
    name: "applicants-id-edit",
    path: "/applicants/:id()/edit",
    component: () => import("/home/vsts/work/1/s/packages/admin-dashboard/pages/applicants/[id]/edit.vue")
  },
  {
    name: "applicants-id-show",
    path: "/applicants/:id()/show",
    component: () => import("/home/vsts/work/1/s/packages/admin-dashboard/pages/applicants/[id]/show.vue")
  },
  {
    name: "applicants",
    path: "/applicants",
    component: () => import("/home/vsts/work/1/s/packages/admin-dashboard/pages/applicants/index.vue")
  },
  {
    name: "applications-id-stepId-edit",
    path: "/applications/:id()/:stepId()/edit",
    component: () => import("/home/vsts/work/1/s/packages/admin-dashboard/pages/applications/[id]/[stepId]/edit.vue")
  },
  {
    name: "applications-id-stepId-household-members",
    path: "/applications/:id()/:stepId()/household-members",
    component: () => import("/home/vsts/work/1/s/packages/admin-dashboard/pages/applications/[id]/[stepId]/household-members.vue")
  },
  {
    name: "applications-id-print",
    path: "/applications/:id()/print",
    meta: printgejMXA8qiuMeta || {},
    component: () => import("/home/vsts/work/1/s/packages/admin-dashboard/pages/applications/[id]/print.vue")
  },
  {
    name: "applications-id-show",
    path: "/applications/:id()/show",
    meta: showhOVbs8UlAlMeta || {},
    component: () => import("/home/vsts/work/1/s/packages/admin-dashboard/pages/applications/[id]/show.vue")
  },
  {
    name: "applications",
    path: "/applications",
    component: () => import("/home/vsts/work/1/s/packages/admin-dashboard/pages/applications/index.vue")
  },
  {
    name: "appointments-id",
    path: "/appointments/:id()",
    component: () => import("/home/vsts/work/1/s/packages/admin-dashboard/pages/appointments/[id].vue")
  },
  {
    name: "appointments-availability",
    path: "/appointments/availability",
    component: () => import("/home/vsts/work/1/s/packages/admin-dashboard/pages/appointments/availability.vue")
  },
  {
    name: "appointments-calendar",
    path: "/appointments/calendar",
    component: () => import("/home/vsts/work/1/s/packages/admin-dashboard/pages/appointments/calendar.vue")
  },
  {
    name: "appointments",
    path: "/appointments",
    component: () => import("/home/vsts/work/1/s/packages/admin-dashboard/pages/appointments/index.vue")
  },
  {
    name: "appointments-manage",
    path: "/appointments/manage",
    component: () => import("/home/vsts/work/1/s/packages/admin-dashboard/pages/appointments/manage.vue")
  },
  {
    name: "documents-preview",
    path: "/documents/preview",
    meta: previewt5qgJSVh58Meta || {},
    component: () => import("/home/vsts/work/1/s/packages/admin-dashboard/pages/documents/preview.vue")
  },
  {
    name: "dynamic-form-territory",
    path: "/dynamic-form/territory",
    component: () => import("/home/vsts/work/1/s/packages/admin-dashboard/pages/dynamic-form/territory.vue")
  },
  {
    name: "faqs",
    path: "/faqs",
    component: () => import("/home/vsts/work/1/s/packages/admin-dashboard/pages/faqs/index.vue")
  },
  {
    name: "funding-sources-id",
    path: "/funding-sources/:id()",
    component: () => import("/home/vsts/work/1/s/packages/admin-dashboard/pages/funding-sources/[id]/index.vue")
  },
  {
    name: "funding-sources-id-print",
    path: "/funding-sources/:id()/print",
    meta: print3FUVu6CKniMeta || {},
    component: () => import("/home/vsts/work/1/s/packages/admin-dashboard/pages/funding-sources/[id]/print.vue")
  },
  {
    name: "funding-sources-create",
    path: "/funding-sources/create",
    component: () => import("/home/vsts/work/1/s/packages/admin-dashboard/pages/funding-sources/create.vue")
  },
  {
    name: "funding-sources",
    path: "/funding-sources",
    component: () => import("/home/vsts/work/1/s/packages/admin-dashboard/pages/funding-sources/index.vue")
  },
  {
    name: "funds-id",
    path: "/funds/:id()",
    component: () => import("/home/vsts/work/1/s/packages/admin-dashboard/pages/funds/[id]/index.vue")
  },
  {
    name: "funds-id-print",
    path: "/funds/:id()/print",
    meta: print1oJ3LNouJUMeta || {},
    component: () => import("/home/vsts/work/1/s/packages/admin-dashboard/pages/funds/[id]/print.vue")
  },
  {
    name: "funds-create",
    path: "/funds/create",
    component: () => import("/home/vsts/work/1/s/packages/admin-dashboard/pages/funds/create.vue")
  },
  {
    name: "funds",
    path: "/funds",
    component: () => import("/home/vsts/work/1/s/packages/admin-dashboard/pages/funds/index.vue")
  },
  {
    name: "generate-error",
    path: "/generate-error",
    component: () => import("/home/vsts/work/1/s/packages/admin-dashboard/pages/generate-error.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/home/vsts/work/1/s/packages/admin-dashboard/pages/index.vue")
  },
  {
    name: "oversight",
    path: "/oversight",
    component: () => import("/home/vsts/work/1/s/packages/admin-dashboard/pages/oversight/index.vue")
  },
  {
    name: "pdf-summary",
    path: "/pdf/summary",
    meta: summaryPEzI3cJCkYMeta || {},
    component: () => import("/home/vsts/work/1/s/packages/admin-dashboard/pages/pdf/summary.vue")
  },
  {
    name: "reports-disaster-distributions",
    path: "/reports/disaster-distributions",
    component: () => import("/home/vsts/work/1/s/packages/admin-dashboard/pages/reports/disaster-distributions.vue")
  },
  {
    name: "reports-food-distributions",
    path: "/reports/food-distributions",
    component: () => import("/home/vsts/work/1/s/packages/admin-dashboard/pages/reports/food-distributions.vue")
  },
  {
    name: "reports-fund-transactions",
    path: "/reports/fund-transactions",
    component: () => import("/home/vsts/work/1/s/packages/admin-dashboard/pages/reports/fund-transactions/index.vue")
  },
  {
    name: "schedule",
    path: "/schedule",
    component: () => import("/home/vsts/work/1/s/packages/admin-dashboard/pages/schedule/index.vue")
  },
  {
    name: "service-areas-id",
    path: "/service-areas/:id()",
    component: () => import("/home/vsts/work/1/s/packages/admin-dashboard/pages/service-areas/[id].vue")
  },
  {
    name: "service-areas-create",
    path: "/service-areas/create",
    component: () => import("/home/vsts/work/1/s/packages/admin-dashboard/pages/service-areas/create.vue")
  },
  {
    name: "service-areas",
    path: "/service-areas",
    component: () => import("/home/vsts/work/1/s/packages/admin-dashboard/pages/service-areas/index.vue")
  }
]