<template>
  <NuxtLayout>
    <NuxtPage :keepalive="false" :pageKey="route.fullPath" />
  </NuxtLayout>
</template>
<script lang="ts" setup>
const route = useRoute();
watch(route, () => {
  console.log('Navigating to a new page', route.fullPath);
});
useHead({
  bodyAttrs: {
    class: '!font-proxima'
  }
})

const router = useRouter();
const dashboardStore = useDashboardStore();

const userStore = useUserStore();
if (localStorage.getItem('userUpdate') !== undefined && localStorage.getItem('userUpdate') === 'yes') {
  localStorage.removeItem('userUpdate');
  await userStore.getCurrentUser();

  if (userStore.isSysAdmin) {
    await router.push('/dynamic-form/territory');
  } else if (userStore.hasOversight) {
    dashboardStore.clearData();
    await router.push('/oversight');
  } else {
    await router.push('/');
  }
}
</script>
