import { defineStore } from 'pinia';
import { ServiceArea } from '~/composables/types/ServiceArea';
export const useDashboardStore = defineStore(
  'dashboard',
  () => {
    const selectedServiceArea = ref<string[]>([]);
    const selectedLicensedLocation = ref<number[]>([]);
    const selectedDivision = ref<string[]>([]);
    const selectedTerritory = ref<string[]>([]);
    const selectedDate = ref<string>('');
    const dbServiceAreas = ref<ServiceArea[]>([]);
    const setSelectedServiceArea = (serviceArea: string[]) => {
      selectedServiceArea.value = serviceArea;
    };
    const setSelectedLicensedLocation = (licensedLocation: number[]) => {
      selectedLicensedLocation.value = licensedLocation;
    };
    const setSelectedDivision = (division: string[]) => {
      selectedDivision.value = division;
    };
    const setSelectedTerritory = (territory: string[]) => {
      selectedTerritory.value = territory;
    };
    const setSelectedDate = (date: string) => {
      selectedDate.value = date;
    };

    const setDbServiceAreas = (serviceAreas: ServiceArea[]) => {
      dbServiceAreas.value = serviceAreas;
    };

    const clearData = () => {
      selectedServiceArea.value = [];
      selectedLicensedLocation.value = [];
      selectedDivision.value = [];
      selectedTerritory.value = [];
      selectedDate.value = 'year';
    };

    const getCountStatuses = (startDate: string, endDate: string, serviceAreaIds: string[]) => {
      const serviceAreas = serviceAreaIds
        .map((id) => {
          return `serviceAreaIds=${id}`;
        })
        .join('&');

      return $fetch<{ success: boolean; data: { status: string; totalRequests: number }[] }>(
        `/dashboard/counts/statuses?startDate=${startDate}&endDate=${endDate}&${serviceAreas}`,
        {
          ...globalFetchOptions(),
          method: 'get',
        }
      );
    };

    const getMapPins = (startDate: string, endDate: string, serviceAreaIds: string[]) => {
      const serviceAreas = serviceAreaIds
        .map((id) => {
          return `serviceAreaIds=${id}`;
        })
        .join('&');

      return $fetch<{
        success: boolean;
        data: {
          assistanceRequestId: string;
          firstName: string;
          lastName: string;
          latitude: number;
          longitude: number;
        }[];
      }>(`/dashboard/map/pins?startDate=${startDate}&endDate=${endDate}&${serviceAreas}`, {
        ...globalFetchOptions(),
        method: 'get',
      });
    };

    const getCountyCount = (startDate: string, endDate: string, serviceAreaIds: string[]) => {
      const serviceAreas = serviceAreaIds
        .map((id) => {
          return `serviceAreaIds=${id}`;
        })
        .join('&');

      return $fetch<{
        success: boolean;
        data: {
          counts: {
            county: string;
            totalAmountApproved: number;
            totalRequests: number;
          }[];
          center: {
            latitude: number;
            longitude: number;
          };
        };
      }>(`/dashboard/counts/county?startDate=${startDate}&endDate=${endDate}&${serviceAreas}`, {
        ...globalFetchOptions(),
        method: 'get',
      });
    };

    const getCountStatusesDate = (startDate: string, endDate: string, serviceAreaIds: string[]) => {
      const serviceAreas = serviceAreaIds
        .map((id) => {
          return `serviceAreaIds=${id}`;
        })
        .join('&');

      return $fetch<{ success: boolean; data: { status: string; totalRequests: number }[] }>(
        `/dashboard/counts/statuses/date?startDate=${startDate}&endDate=${endDate}&${serviceAreas}`,
        {
          ...globalFetchOptions(),
          method: 'get',
        }
      );
    };

    const assistanceRequestIsLoading = ref<boolean>(false);
    const setAssistanceRequestIsLoading = (value: boolean) => {
      assistanceRequestIsLoading.value = value;
    };
    const fundIsLoading = ref<boolean>(false);
    const setFundIsLoading = (value: boolean) => {
      fundIsLoading.value = value;
    };
    const fundPieIsLoading = ref<boolean>(false);
    const setFundPieIsLoading = (value: boolean) => {
      fundPieIsLoading.value = value;
    };
    return {
      selectedServiceArea,
      selectedDate,
      setSelectedServiceArea,
      setSelectedDate,
      selectedLicensedLocation,
      setSelectedLicensedLocation,
      selectedDivision,
      setSelectedDivision,
      selectedTerritory,
      setSelectedTerritory,
      getCountStatuses,
      getCountStatusesDate,
      getMapPins,
      getCountyCount,
      dbServiceAreas,
      setDbServiceAreas,
      clearData,
      assistanceRequestIsLoading,
      setAssistanceRequestIsLoading,
      fundIsLoading,
      setFundIsLoading,
      fundPieIsLoading,
      setFundPieIsLoading,
    };
  },
  {
    persist: true,
  }
);
