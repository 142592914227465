import { defineStore } from 'pinia';
import { useMutation, useQuery } from '@tanstack/vue-query';
import { FormFieldFormStep, FormFieldLabelValue } from '~/composables/types/DynamicForm';
import { Ref } from 'vue';
import { HTTPMethod } from 'h3';

export const useDynamicFormStore = defineStore('dynamic_form', () => {
  const getAll = () => {
    const fetcher = async () =>
      $fetch<{ success: boolean; data: FormFieldFormStep[] }>(`/form-field-step`, {
        ...globalFetchOptions(),
        method: 'get',
      });

    return useQuery({ queryKey: ['form-field-form-step'], queryFn: fetcher, ...globalUseQueryOptions() });
  };

  const getByTerritory = () => {
    const fetcher = async () =>
      $fetch<{ success: boolean; data: FormFieldFormStep }>(`/form-field-step/territory`, {
        ...globalFetchOptions(),
        method: 'get',
      });

    return useQuery({ queryKey: ['form-field-form-step-territory'], queryFn: fetcher, ...globalUseQueryOptions() });
  };

  const getNamesByTerritory = () => {
    return $fetch<{ success: boolean; data: FormFieldLabelValue }>(`/form-field-step/territory/names`, {
      ...globalFetchOptions(),
      method: 'get',
    });
  };

  const getByTerritoryId = (param: Ref<number[] | undefined>) => {
    const fetcher = async () =>
      $fetch<{ success: boolean; data: FormFieldFormStep }>(
        `/form-field-step/territory/${param.value ? param.value[0] : 1}`,
        {
          ...globalFetchOptions(),
          method: 'get',
        }
      );

    return useQuery({ queryKey: ['form-field-form-step-territory'], queryFn: fetcher, ...globalUseQueryOptions() });
  };

  const getByFundingSource = (id: string) => {
    const fetcher = async () =>
      $fetch<{ success: boolean; data: FormFieldFormStep }>(`/form-field-step/funding-source/${id}`, {
        ...globalFetchOptions(),
        method: 'get',
      });

    return useQuery({ queryKey: ['form-field-form-step-funding-source'], queryFn: fetcher, ...globalUseQueryOptions() });
  };

  const getByAssistanceType = (id: string) => {
    const fetcher = async () =>
      $fetch<{ success: boolean; data: FormFieldFormStep }>(`/form-field-step/assistance-types/${id}`, {
        ...globalFetchOptions(),
        method: 'get',
      });

    return useQuery({ queryKey: ['form-field-form-step-assistance-type', id], queryFn: fetcher, ...globalUseQueryOptions() });
  };

  const getByAssistanceTypeAsync = (id: string) => {
    return $fetch<{ success: boolean; data: FormFieldFormStep }>(`/form-field-step/assistance-types/${id}`, {
      ...globalFetchOptions(),
      method: 'get',
    });
  };

  const setTerritoryField = () => {
    return useMutation((formData: { action: HTTPMethod; id: string; territory: number }) =>
      $fetch<{ success: true; data: string }>(
        `/form-field-step/${formData.id}/territory/${formData.territory}/?isRequired=${
          formData.action === 'PUT' ? 'true' : 'false'
        }`,
        {
          ...globalFetchOptions(),
          method: formData.action === 'PUT' ? 'POST' : formData.action,
          retry: false,
        }
      )
    );
  };

  const setFundingSourceField = (fundingSourceId: string) => {
    return useMutation((formData: { action: HTTPMethod; id: string }) =>
      $fetch<{ success: true; data: string }>(
        `/form-field-step/${formData.id}/funding-source/${fundingSourceId}/${
          formData.action === 'DELETE' ? '' : formData.action === 'PUT' ? 1 : formData.action === 'PATCH' ? 2 : 0
        }`,
        {
          ...globalFetchOptions(),
          method: formData.action !== 'DELETE' ? 'POST' : formData.action,
          retry: false,
        }
      )
    );
  };

  const setAssistanceTypeField = (id: string) => {
    return useMutation((formData: { action: HTTPMethod; id: string }) =>
      $fetch<{ success: true; data: string }>(
        `/form-field-step/${formData.id}/assistance-types/${id}/${
          formData.action === 'DELETE' ? '' : formData.action === 'PUT' ? 1 : formData.action === 'PATCH' ? 2 : 0
        }`,
        {
          ...globalFetchOptions(),
          method: formData.action !== 'DELETE' ? 'POST' : formData.action,
          retry: false,
        }
      )
    );
  };

  return {
    getAll,
    setTerritoryField,
    getByTerritory,
    getNamesByTerritory,
    getByTerritoryId,
    getByFundingSource,
    setFundingSourceField,
    getByAssistanceType,
    setAssistanceTypeField,
    getByAssistanceTypeAsync,
  };
});
